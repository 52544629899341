import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ItineraryMap from "./pages/itinerary-map";

const router = createBrowserRouter([
    {
        path: "/",
        element: <ItineraryMap />,
    },
    {
        path: "/itinerary-map",
        element: <ItineraryMap />,
    }
]);

export default function Layout() {
    return (
        <RouterProvider router={router} />
    )
}
